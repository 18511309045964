import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from 'environments/environment';

export abstract class BaseDataService {
  public constructor(protected http: HttpClient) { }

  protected get url(): string {
    return environment.azureApiManagementUrl;
  }

  protected getRequest<T>(url: string, params?: HttpParams): Observable<T> {
    return this.http.get<T>(url, { params });
  }
}
