import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { DsLoggingService } from '@cubigo/digital-signage';

import { OAuthService, TokenResponse } from 'angular-oauth2-oidc';
import { Observable, Subscription, from } from 'rxjs';

import { LocalStorageKey } from '../enums';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  private subscriptions: Subscription[] = [];
  private authServiceSubscription: Subscription;

  private tokenRefreshRetryCount = 0;
  private tokenRefreshBackoffDurations: number[] = [30000, 60000, 90000]; // 30s 60s 90s

  public constructor(private oauthService: OAuthService,
    private router: Router,
    private loggingService: DsLoggingService) {

    this.addAuthEventListener();
  }

  public get accessToken(): string {
    return this.oauthService.getAccessToken();
  }

  public get isTokenValid(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  public async validateAndRefreshToken(): Promise<boolean> {
    let hasValidToken = this.isTokenValid;

    // Try to refresh the token if there are tokens available
    if (hasValidToken === false && this.accessToken) {
      await this.oauthService.refreshToken()
        .then(() => {
          hasValidToken = this.isTokenValid;
        }).catch(() => {
          this.logoutAndNavigate();
        });
    }

    return hasValidToken;
  }

  public login(email: string, pinCode: string): Observable<TokenResponse> {
    return from(this.oauthService.fetchTokenUsingPasswordFlow(email, pinCode));
  }

  public refreshToken(): void {
    this.loggingService.trackEvent('Refreshing token.', this.getLogProperties());
    this.oauthService.refreshToken()
      .then(() => {
        this.tokenRefreshRetryCount = 0;
        this.addAuthEventListener();
      })
      .catch(() => {
        this.retryTokenRefresh();
      });
  }

  public logoutAndNavigate(): void {
    this.oauthService.logOut();
    const loginHint = localStorage.getItem(LocalStorageKey.LoginHint);
    this.loggingService.trackEvent(`User with email ${loginHint} logged out.`, this.getLogProperties());
    this.router.navigate(['/login']);
  }

  private addAuthEventListener() {
    // Test case to see if the subscription gets lost after some time
    // This method now always creates a new subscription and kills the previous one
    this.authServiceSubscription?.unsubscribe();
    this.authServiceSubscription = this.oauthService.events.subscribe({
      next: (event) => {
        if (event.type === 'token_expires') {
          this.refreshToken();
        }
      },
    });
  }

  private retryTokenRefresh(): void {
    if (this.tokenRefreshRetryCount > 2) {
      // Maximum 3 retries are allowed (0,1,2)
      this.loggingService.trackEvent(`Token refresh failed after 3 attempts.`);
      this.logoutAndNavigate();
      return;
    }

    // Get the timeout duration for the next token refresh
    const timeoutDuration = this.tokenRefreshBackoffDurations[this.tokenRefreshRetryCount];

    setTimeout(() => {
      // Trigger the next refresh after x time
      this.refreshToken();
    }, timeoutDuration);

    this.tokenRefreshRetryCount++;
  }

  private getLogProperties(): any {
    return {
      accessToken: this.accessToken,
      refreshToken: this.oauthService.getRefreshToken(),
      hasValidToken: this.isTokenValid
    };
  }
}
