import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import { DataCenterPrefix, LocalStorageKey } from 'app/shared/enums';

import { environment } from 'environments/environment';

@Injectable()
export class RegionInterceptor implements HttpInterceptor {
  public constructor(
    private router: Router,
  ) {
  }

  private get azureApiManagementUrl() { return environment.azureApiManagementUrl; }
  private get ssoUrl() { return environment.ssoUrl + 'identity/connect/token'; }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const url = request.url.toLowerCase();

    if (url.startsWith(this.azureApiManagementUrl) ||
      url.startsWith(this.ssoUrl)) {

      this.setPrefixToApiCall(request);
    }

    return next.handle(request);
  }

  private setPrefixToApiCall(request: HttpRequest<any>): void {
    const dataCenterPrefix = localStorage.getItem(LocalStorageKey.DataCenterPrefix) || '';
    if (dataCenterPrefix && (Object as any).values(DataCenterPrefix).includes(dataCenterPrefix.toUpperCase())) {
      if (dataCenterPrefix === DataCenterPrefix.US || dataCenterPrefix === DataCenterPrefix.Unknown) {
        return;
      }

      const url = request.url.toLowerCase();
      const urlWithParams = request.urlWithParams;

      const newUrl = {
        url: this.addPrefixToUrl(url, dataCenterPrefix),
        urlWithParams: this.addPrefixToUrl(urlWithParams, dataCenterPrefix)
      };

      request = Object.assign(request, newUrl);

      return;
    }

    // if you have no prefix you should login
    this.router.navigate(['/login']);
  }

  private addPrefixToUrl(url: string, dataCenterPrefix: string): string {
    const cutIndex = url.indexOf('//');
    return `${url.substring(0, cutIndex + 2)}${dataCenterPrefix}-${url.substring(cutIndex + 2)}`;
  }
}
