import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';

import { ConfigurationState, ChannelYouTubeInfo, ServiceTypeItem } from '@cubigo/digital-signage';

import { BehaviorSubject, Observable } from 'rxjs';

import { BaseDataService } from './base-data.service';
@Injectable({
  providedIn: 'root'
})
export class ConfigDataService extends BaseDataService implements OnDestroy {
  public configuration: BehaviorSubject<ConfigurationState> = new BehaviorSubject<ConfigurationState>(null);

  public constructor(
    protected override http: HttpClient,
  ) {
    super(http);
  }

  public ngOnDestroy(): void {
    this.configuration.unsubscribe();
  }

  public getConfig(): Observable<ConfigurationState> {
    const url = `${this.url}Services/SyndicatorConfiguration`;
    return this.http.get<ConfigurationState>(url);
  }

  public getYoutubeLive(channelId: number): Observable<ChannelYouTubeInfo> {
    const url = `${this.url}Services/ProviderChannel/${channelId}/YouTubeInfo`;
    return this.http.get<ChannelYouTubeInfo>(url);
  }

  public getServiceNames(): Observable<ServiceTypeItem[]> {
    const url = `${this.url}Syndication/Service/ServiceNames`;
    return this.http.get<ServiceTypeItem[]>(url);
  }
}
