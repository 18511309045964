import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Branding } from '@cubigo/digital-signage';

import { Observable } from 'rxjs';

import { BaseDataService } from './base-data.service';

@Injectable({
  providedIn: 'root'
})
export class BrandingDataService extends BaseDataService {

  public constructor(
    protected override http: HttpClient,
  ) {
    super(http);
  }

  public getBranding(): Observable<Branding> {
    const url = `${this.url}Branding/community`;
    return this.http.get<Branding>(url);
  }
}
