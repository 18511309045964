import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { DsLoggingService } from '@cubigo/digital-signage';

import {
  AuthService,
} from 'app/shared/services';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private logoutStarted: boolean;

  // Error handling is important and needs to be loaded first.
  // Because of this we should manually inject the services with Injector.
  public constructor(
    private injector: Injector) {
  }

  public handleError(error: any): void {
    let message: string;
    let consoleMessage: string;
    let errorToHandle: Error;

    // error.rejection should give you the HttpErrorResponse when you use .toPromise()
    if (error.rejection) {
      error = error.rejection;
    }

    if (error instanceof HttpErrorResponse) {
      // Server Error
      message = this.getHttpErrorResponseMessage(error);
      consoleMessage = message;
      errorToHandle = error.error;

      // User session ended so logout, check boolean for async calls so that the popup is only shown once.
      if (error.status === 401) {
        const authService = this.injector.get(AuthService);

        authService.logoutAndNavigate();
      }

    } else {
      // Other Errors like client errors
      message = this.getClientMessage(error);
      consoleMessage = this.getConsoleMessage(error);
      errorToHandle = error;
    }

    this.logError(errorToHandle);
    this.showErrorInConsole(consoleMessage);
  }

  private getClientMessage(error: Error): string {
    if (!error) {
      return null;
    }

    return error.message ? error.message : error.toString();
  }

  private getConsoleMessage(error: Error): string {
    if (!error) {
      return null;
    }

    let message = '';
    if (error.name) {
      message = error.name + ': ';
    }

    if (error.message) {
      message = message + error.message;
    } else {
      message = message + error.toString();
    }

    if (error.stack) {
      message = message + ' (' + error.stack + ')';
    }

    return message;
  }

  private getHttpErrorResponseMessage(error: HttpErrorResponse): string {
    if (error.error instanceof Error) {
      // A client-side or network error occurred. Handle it accordingly.
      return error.error.message;
    } else if (error.error instanceof ProgressEvent) {
      return error.message;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      return error.error;
    }
  }

  private showErrorInConsole(message: string): void {
    try {
      console.group('logErrorToConsole');
      console.error(message);
      console.groupEnd();
    } catch (handlingError) {
      console.group('logErrorToConsole');
      console.error('Error when trying to output error.');
      console.error(handlingError);
      console.groupEnd();
    }
  }

  private logError(error: Error): void {
    const exceptionLoggingService = this.injector.get(DsLoggingService);

    try {
      exceptionLoggingService.logError(error);
    } catch (handlingError) {
      console.group('logErrorToLog');
      console.error(handlingError);
      console.groupEnd();
    }
  }
}
