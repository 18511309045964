import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MealItem, DiningLocation } from '@cubigo/digital-signage';

import { Observable } from 'rxjs';

import { BaseDataService } from 'app/shared/services/data';

@Injectable({
  providedIn: 'root'
})
export class DiningDataService extends BaseDataService {

  public constructor(
    protected override http: HttpClient,
  ) {
    super(http);
  }

  public getDining(providerId: string, channelId: number): Observable<MealItem[]> {
    const dateOfToday: string = new Date().toISOString().split('T')[0];
    const url = `${this.url}Syndication/Dining/Provider/${providerId}/Meals/On/${dateOfToday}?channelId=${channelId}`;
    return this.getRequest<MealItem[]>(url);
  }

  public getDiningLocations(providerId: string): Observable<DiningLocation[]> {
    const url = `${this.url}Syndication/Dining/Provider/${providerId}/locations`;
    return this.getRequest<DiningLocation[]>(url);
  }
}
