import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { DataCenterRegionResponse, DataCenterPrefix } from '@cubigo/digital-signage';

import { Observable, map } from 'rxjs';

import { BaseDataService } from 'app/shared/services/data';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataCenterDataService extends BaseDataService {

  public constructor(protected override http: HttpClient) {
    super(http);
  }

  public getDataCenterRegionOfUser(userEmail: string): Observable<DataCenterRegionResponse> {
    const url = `${environment.sharedSsoApi}region`;
    const params = new HttpParams().set('useremail', userEmail);

    return this.getRequest<DataCenterRegionResponse>(url, params).pipe(map(data => {
      if (data === null) {
        return { region: DataCenterPrefix.Unknown };
      }

      if ((Object as any).values(DataCenterPrefix).includes(data.region.toUpperCase())) {
        return data;
      }

      return { region: DataCenterPrefix.Unknown };
    }));
  }
}
