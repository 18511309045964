import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { WeatherResponse } from '@cubigo/digital-signage';

import { Observable } from 'rxjs';

import { BaseDataService } from './base-data.service';

@Injectable({
  providedIn: 'root'
})
export class FooterDataService extends BaseDataService {

  public constructor(
    protected override http: HttpClient,
  ) {
    super(http);
  }

  public getWeather(): Observable<WeatherResponse> {
    const url = `${this.url}OpenWeatherMap/GetCommunityWeather`;
    return this.http.get<WeatherResponse>(url);
  }

  public getMailArrived(): Observable<any> {
    const url = `${this.url}syndication/mail`;
    return this.http.get<string>(url);
  }
}
