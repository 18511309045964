import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '../services';

export const authGuard: CanActivateFn = async () => {
  const authService = inject(AuthService);

  const isAuthenticated = await authService.validateAndRefreshToken();

  if (!isAuthenticated) {
    // Redirect to the login page
    authService.logoutAndNavigate();
  }

  return isAuthenticated;
};
