import { AuthConfig } from 'angular-oauth2-oidc';

import { environment } from 'environments/environment';

export const authConfig: AuthConfig = {
  issuer: environment.ssoUrl.slice(0, -1),
  clientId: environment.identityServer.clientId,
  dummyClientSecret: environment.identityServer.clientSecret,
  scope: environment.identityServer.scope,
  tokenEndpoint: environment.ssoUrl + 'identity/connect/token',
  oidc: true,

  sessionChecksEnabled: false,
  showDebugInformation: false,
  requireHttps: false,
  clearHashAfterLogin: false,
};
