import { Component, OnInit } from '@angular/core';
import { DataCenterPrefix, DsLoggingService, LocalStorageKey, RefreshService } from '@cubigo/digital-signage';

import { environment } from 'environments/environment';

import appVersion from '../../package.json';
import { ServiceWorkerUpdaterService } from './shared/services/service-worker-updater.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  public constructor(private serviceWorkerUpdater: ServiceWorkerUpdaterService,
    private dsLoggingService: DsLoggingService,
    private refreshService: RefreshService) { }

  public ngOnInit(): void {
    this.serviceWorkerUpdater.init();

    this.initLoggingService();
    this.scheduleReload();
    this.refreshService.init(environment.refreshDuration);
  }

  private initLoggingService() {
    let dataCenterPrefix = localStorage.getItem(LocalStorageKey.DataCenterPrefix);

    // If there is no DataCenterPrefix present, use Unknown as a fallback
    // This means that the user has not logged in yet, so it should get set correctly after the login
    if (dataCenterPrefix == null) {
      dataCenterPrefix = DataCenterPrefix.Unknown;
    }

    this.dsLoggingService.appVersion = appVersion.version;
    this.dsLoggingService.setInstrumentationKeyOnRegion(dataCenterPrefix);
  }

  private scheduleReload(): void {
    // Schedula a daily reload an night with a random time between 0 and 2 hours after midnight
    const now = new Date();
    const midnight = new Date();
    midnight.setHours(24, 0, 0, 0);  // Set to the next midnight

    const timeUntilMidnight = midnight.getTime() - now.getTime();
    const randomTimeAfterMidnight = Math.random() * 2 * 60 * 60 * 1000; // Random time between 0 and 2 hours in milliseconds

    const scheduledTime = new Date(now.getTime() + timeUntilMidnight + randomTimeAfterMidnight);
    this.dsLoggingService.trackTrace(`Scheduled nightly reload at ${scheduledTime.toISOString()}`);

    const totalTime = timeUntilMidnight + randomTimeAfterMidnight;

    setTimeout(() => {
      this.reloadIfPossible();
    }, totalTime);
  }

  private reloadIfPossible(): void {
    // Check if there is an active internet connection, otherwise wait another 5 minutes
    if (navigator.onLine) {
      this.dsLoggingService.trackTrace('Nightly reload triggered.');
      window.location.reload();
    } else {
      setTimeout(() => {
        this.reloadIfPossible();
      }, 5 * 60 * 1000); // Check again in 5 minutes
    }
  }
}
