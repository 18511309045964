import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/shared/services';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html'
})
export class LogoutComponent implements OnInit {

  public constructor(private authService: AuthService, private router: Router) { }

  public ngOnInit(): void {
    this.authService.logoutAndNavigate();
  }

}
