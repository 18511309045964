import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';

import { LocalStorageKey, ConfigurationState, DsLoggingService } from '@cubigo/digital-signage';

import { Observable, Subscription } from 'rxjs';

import { ConfigDataService } from 'app/shared/services/data';
import { AuthService } from 'app/shared/services';
import { DataCenterPrefix } from 'app/shared/enums';

import { environment } from 'environments/environment';


@Injectable()
export class HeaderInterceptor implements HttpInterceptor, OnDestroy {
  private providerId = '';
  private subscriptions: Subscription[] = [];

  public constructor(
    private loggingService: DsLoggingService,
    private authService: AuthService,
    private configDataService: ConfigDataService,
  ) {
    this.subscriptions.push(this.configDataService.configuration.subscribe({
      next: (configurationState: ConfigurationState) => {
        if (!configurationState) {
          return;
        }
        this.providerId = configurationState.channel.providerUuid || '';
      }
    }));
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.startsWith(environment.sharedSsoApi) || request.url.startsWith('/assets')) {
      return next.handle(request);
    }
    return this.handleRequest(request, next);
  }


  private handleRequest(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.authService.accessToken;
    request = request.clone({
      setHeaders: {
        'Ocp-Apim-Subscription-Key': this.getSubscriptionKey(),
      }
    });

    // Send the operation.id from Application Insight from client to the server (Web Api)
    // this creates a parent - child relation between the request
    request = request.clone({ headers: request.headers.set('x-ms-request-root-id', this.loggingService.getTraceId()) });

    if (this.providerId) {
      request = request.clone({ headers: request.headers.set('CommunityUuid', this.providerId) });
    }

    if (token) {
      request = request.clone({ headers: request.headers.set('Authorization', `Bearer ${token}`) });
    }

    const cultureCode = localStorage.getItem(LocalStorageKey.DSLanguage);

    request = request.clone({ headers: request.headers.set('Accept-Language', cultureCode ? cultureCode : 'en-US') });

    if (this.loggingService.deviceId) {
      request = request.clone({ headers: request.headers.set('device-Id', this.loggingService.deviceId) });
    }

    const userAgent = this.loggingService.userAgent;
    if (userAgent) {
      request = request.clone({ headers: request.headers.set('x-user-agent', userAgent) });
    }
    return next.handle(request);
  }

  private getSubscriptionKey(): string {
    const dataCenterPrefix = localStorage.getItem(LocalStorageKey.DataCenterPrefix) || null;

    if (!dataCenterPrefix) {
      return environment.ocpApimSubscriptionKey;
    }

    switch (dataCenterPrefix) {
      case DataCenterPrefix.EU:
        return environment.ocpApimSubscriptionKeyEu;
      case DataCenterPrefix.CA:
        return environment.ocpApimSubscriptionKeyCa;
      default:
        return environment.ocpApimSubscriptionKey;
    }
  }
}
