import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ActivityItem, ScheduledActivity } from '@cubigo/digital-signage';

import { Observable } from 'rxjs';

import { BaseDataService } from 'app/shared/services/data/base-data.service';

@Injectable({
  providedIn: 'root'
})
export class ActivityDataService extends BaseDataService {

  public constructor(
    protected override http: HttpClient,
  ) {
    super(http);
  }

  public getActivities(providerId: string, channelId: number): Observable<ActivityItem[]> {
    const dateOfToday: string = new Date().toISOString().split('T')[0];
    const url = `${this.url}Syndication/Activities/Provider/${providerId}/Activities/On/${dateOfToday}?channelId=${channelId}`;
    return this.getRequest<ActivityItem[]>(url);
  }

  public getScheduledActivities(providerId: string, channelId: number): Observable<ScheduledActivity[]> {
    const url = `${this.url}Syndication/YouTubeActivities/Provider/${providerId}/YouTubeActivities?channelId=${channelId}`;
    return this.getRequest<any>(url);
  }
}
