<div class="login-page">
    <form [formGroup]="loginForm"
        (ngSubmit)="onSubmit()">
        <h1>{{ 'LOGIN.Welcome' | translate }}</h1>
        <input id="email"
            name="email"
            type="email"
            [placeholder]="'LOGIN.EmailPlaceholder' | translate"
            autocomplete="email"
            formControlName="email" 
            (keyup)="onKey()"/>
        <span class="error"
            *ngIf="emailControl.invalid && emailControl.dirty && emailControl.touched">
            {{ 'LOGIN.EmailError' | translate }}
        </span>

        <input id="pinCode"
            name="pinCode"
            type="password"
            [placeholder]="'LOGIN.PasswordPlaceholder' | translate"
            formControlName="password"
            (keyup)="onKey()" />
        <span class="error"
            *ngIf="passwordControl.invalid && passwordControl.touched">
            {{ 'LOGIN.PasswordError' | translate }}
        </span>

        <p *ngIf="loginFailed"
            id="error">{{ 'LOGIN.LoginFailed' | translate }}</p>

        <input id="submit"
            name="submit"
            type="submit"
            value="Log In"
            [disabled]="loginForm.invalid || isLoading" />
    </form>
    <p id="cubigo-subtext">{{ 'FOOTER.PoweredByCubigo' | translate }}</p>
    <svg id="svg-waves"
        viewBox="0 0 949 1080"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd"
            clip-rule="evenodd"
            d="M438.749 1273.24C991.525 1311.89 1464.26 991.192 1494.62 556.944C1524.99 122.696 1101.49 -260.667 548.713 -299.321C-4.06289 -337.975 708.526 274.577 678.161 708.825C647.795 1143.07 -114.027 1234.58 438.749 1273.24Z"
            fill="white"
            fill-opacity="0.05" />
        <path fill-rule="evenodd"
            clip-rule="evenodd"
            d="M329.696 1333.18C893.939 1290.1 1323.91 895.842 1290.07 452.586C1256.22 9.32976 771.378 -315.075 207.135 -271.994C-357.107 -228.912 453.877 284.099 487.721 727.355C521.565 1170.61 -234.546 1376.27 329.696 1333.18Z"
            fill="white"
            fill-opacity="0.05" />
    </svg>
</div>
