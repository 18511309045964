import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { DsLoggingService } from '@cubigo/digital-signage';

import { environment } from 'environments/environment';
import { take } from 'rxjs';

import { LocalStorageKey } from 'app/shared/enums';
import { AuthService } from 'app/shared/services';
import { DataCenterDataService } from 'app/shared/services/data';
import { emailValidator } from 'app/shared/validators/custom-email.validator';

import { BaseComponentDirective } from '../base/base.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends BaseComponentDirective implements OnInit {
  public loginForm: FormGroup;
  public loginFailed = false;
  public isLoading = false;

  public constructor(
    private authService: AuthService,
    private router: Router,
    private dataCenterDataService: DataCenterDataService,
    private dsLoggingService: DsLoggingService,
  ) {
    super();
  }

  public get emailControl(): AbstractControl {
    return this.loginForm.controls['email'];
  }

  public get passwordControl(): AbstractControl {
    return this.loginForm.controls['password'];
  }

  public ngOnInit(): void {
    const email = localStorage.getItem(LocalStorageKey.LoginHint) || null;
    this.loginForm = new FormGroup({
      email: new FormControl(email, [
        Validators.required,
        emailValidator(),
      ]),
      password: new FormControl(null, Validators.required),
    });
  }

  public onKey(): void {
    this.loginFailed = false;
  }

  public onSubmit(): void {
    if (this.loginForm.invalid) {
      return;
    }

    this.isLoading = true;

    const datacenterSubscription = this.dataCenterDataService.getDataCenterRegionOfUser(this.emailControl.value).pipe(take(1)).subscribe({
      next: (dataCenterPrefix) => {
        this.dsLoggingService.setInstrumentationKeyOnRegion(dataCenterPrefix.region);
        localStorage.setItem(LocalStorageKey.DataCenterPrefix, dataCenterPrefix.region);
        localStorage.setItem(LocalStorageKey.LoginHint, this.emailControl.value);
        this.login();
      },
      error: () => {
        this.loginFailed = true;
        this.isLoading = false;
      },
      complete: () => {
        this.isLoading = false;
      }
    });
    this.subscriptions.push(datacenterSubscription);
  }

  private login(): void {
    const loginSubscription = this.authService.login(this.emailControl.value, this.passwordControl.value).pipe(take(1)).subscribe({
      next: () => {
        this.dsLoggingService.trackEvent(`User with email ${this.emailControl.value} logged in.`);
        this.loginFailed = false;
        this.router.navigate(['']);
      },
      error: () => {
        this.loginFailed = true;
      },
      complete: () => {
        this.isLoading = false;
      }
    });
    this.subscriptions.push(loginSubscription);
  }
}
