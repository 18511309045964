import { HttpClient } from "@angular/common/http";

import { ConfigurationState } from "@cubigo/digital-signage";

import { TranslateLoader } from "@ngx-translate/core";
import { environment } from "environments/environment";
import { BehaviorSubject, Observable, combineLatest, filter, forkJoin, map, of } from "rxjs";
import * as gettext from 'gettext-parser';

import { ConfigDataService } from "../services/data";

export class MultiTranslatePoHttpLoader implements TranslateLoader {
  public defaultCtxt = '';
  public translationCtxt = new BehaviorSubject<string>('');

  public constructor(
    private http: HttpClient,
    private configDataService: ConfigDataService,
    public resources: { prefix: string; suffix: string }[] = [],
    public waitForTranslationContext = true) {
  }

  public getTranslation(lang: string): Observable<any> {
    const resources$ = forkJoin(this.resources.map(config => this.http
      .get(`${config.prefix}/${lang}${config.suffix}`, { responseType: 'text' })));
    const translationContext$ = this.configDataService.configuration.pipe(
      map((configurationState: ConfigurationState) => configurationState?.translationContext != null && configurationState.translationContext !== ''
        ? configurationState.translationContext
        : 'cubigo'));

    return combineLatest([resources$, translationContext$]).pipe(
      filter(([_, context]) => context != null && context !== ''),
      map(([resources, context]) => resources.map((r: string) => this.parse(r, lang, context))),
      map((responses: any[]) => responses.reduce((a, b) => Object.assign(a, b))));
  }

  public parse(contents: string, lang: string, context: string): any {
    const translations: { [key: string]: string } = {};

    const po = gettext.po.parse(contents, 'utf-8');
    if (!Object.prototype.hasOwnProperty.call(po.translations, this.defaultCtxt)) {
      return of(translations);
    }

    let allTranslationKeys: any[] = [];
    let duplicateTranslationKeys: any[] = [];

    const translationsForContext = Object.keys(po.translations ?? {})
      .filter(k => k != null && k.split(',').includes(context))
      .map(k => po.translations[k]);

    translationsForContext.forEach(c => {
      const keysInContext = Object.keys(c);
      duplicateTranslationKeys = duplicateTranslationKeys.concat(keysInContext.filter(k => allTranslationKeys.includes(k)));
      allTranslationKeys = allTranslationKeys.concat(keysInContext);
    });

    // No errors will occur when there are duplicate translations for a key, but this isn't wanted
    if (environment.name?.includes('local') && duplicateTranslationKeys.length > 0) {
      throw new Error('There are duplicate translations for this context.' +
        `Context: ${context}, duplicate keys: ${duplicateTranslationKeys.join(', ')}`);
    }

    Object.keys(po.translations[this.defaultCtxt])
      .forEach(key => {
        if (!key) {
          return;
        }
        if (!po.translations[this.defaultCtxt] || !po.translations[this.defaultCtxt][key]) {
          return;
        }
        if (!po.translations[this.defaultCtxt][key].msgstr) {
          return;
        }

        let translation = '';
        if (context !== '' &&
          translationsForContext.some(t => t[key]?.msgstr != null)) {
          translation = translationsForContext.find(t => t[key]?.msgstr != null)[key].msgstr.pop();
        } else {
          translation = po.translations[this.defaultCtxt][key].msgstr.pop();
        }
        if (key.length > 0) {
          if (translation.length > 0) {
            translations[key] = translation;
          } else {
            translations[key] = `${lang} ${key}`;
          }
        }
      });

    return translations;
  }
}
