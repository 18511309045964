import { Directive, OnDestroy } from "@angular/core";

import { Subscription } from "rxjs";

@Directive()
export abstract class BaseComponentDirective implements OnDestroy {
  protected subscriptions: Subscription[];

  public constructor() {
    this.subscriptions = new Array<Subscription>();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((item: Subscription) => { item.unsubscribe(); });
  }
}
