import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { NewsItem, PriorityNewsItem } from '@cubigo/digital-signage';

import { Observable } from 'rxjs';

import { BaseDataService } from 'app/shared/services/data';

@Injectable({
  providedIn: 'root',
})
export class NewsDataService extends BaseDataService {

  public constructor(
    protected override http: HttpClient,
  ) {
    super(http);
  }

  public getNewsItems(providerId: string, channelId: number): Observable<NewsItem[]> {
    const url = `${this.url}Syndication/News/Provider/${providerId}/NewsItems?channelId=${channelId}`;
    return this.getRequest<NewsItem[]>(url);
  }

  public getBirthdays(providerId: string): Observable<NewsItem[]> {
    const url = `${this.url}Syndication/News/Provider/${providerId}/NewsItems`;
    const params = new HttpParams().set('channelId', '-1');
    return this.getRequest<NewsItem[]>(url, params);
  }

  public getPrioNews(providerId: string, channelId: number): Observable<PriorityNewsItem[]> {
    const url = `${this.url}Syndication/News/Provider/${providerId}/CurrentPriorityNews?channelId=${channelId}`;
    return this.getRequest<PriorityNewsItem[]>(url);
  }
}
