import { Injectable } from '@angular/core';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'

})
export class UnauthorizedRequestService {
  public oldestUnauthorizedRequest: Date = undefined;
  public hasAnySuccessfulCallBeenLogged = false;

  public constructor(private authService: AuthService) { }

  public logUnauthorizedRequest(): void {
    if (this.oldestUnauthorizedRequest == null) {
      this.oldestUnauthorizedRequest = new Date();
    }

    if (this.shouldLogout()) {
      this.authService.logoutAndNavigate();
    }
  }

  public logSucceededRequest(): void {
    this.oldestUnauthorizedRequest = undefined;
    this.hasAnySuccessfulCallBeenLogged = true;
  }

  private shouldLogout(): boolean {
    if (this.oldestUnauthorizedRequest == null) {
      return false;
    }

    // If this is the first request that fails, the user will need to log out
    if (this.hasAnySuccessfulCallBeenLogged === false) {
      return true;
    }

    // Check if the date is older than 10 minutes,
    // In which case we can confidently say the user is no longer authorized
    const oldestDate = this.oldestUnauthorizedRequest;
    const now = new Date();
    const diff = now.getTime() - oldestDate.getTime();
    const isOverTimeLimit = diff > 10 * 60 * 1000;

    if (isOverTimeLimit) {
      this.reset();
      return true;
    }
    return false;
  }

  private reset(): void {
    this.hasAnySuccessfulCallBeenLogged = false;
    this.oldestUnauthorizedRequest = undefined;
  }
}
