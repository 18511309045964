import { AbstractControl, ValidatorFn } from '@angular/forms';

export const emailValidator = (): ValidatorFn => (control: AbstractControl): { [key: string]: any } | null => {
  const email = control.value;
  const responseKo = { invalid: true };
  const responseOk: any = null;

  if (!email) {
    return responseOk;
  }

  // eslint-disable-next-line max-len, no-useless-escape
  const EMAIL_REGEXP = /^((")(".+?"@)|(([0-9a-z]((\.(?!\.))|[-!#\$%&'\*\+=\?\^`\{\}\|~\w])*)@))((\[)(\[(\d{1,3}\.){3}\d{1,3}\])|(([0-9a-z][-\w]*[0-9a-z]*\.)+[a-z0-9][\-a-z0-9]{0,22}[a-z0-9]))$/i;

  if (email !== '' && !EMAIL_REGEXP.test(email)) {
    return responseKo;
  }

  return responseOk;
};
