import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HttpEventType
} from '@angular/common/http';

import { DsLoggingService } from '@cubigo/digital-signage';

import { Observable, of, EMPTY, throwError, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { LocalStorageKey } from 'app/shared/enums';

import { UnauthorizedRequestService } from '../services/unauthorized-request.service';
import { environment } from 'environments/environment';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  public constructor(private unauthorizedRequestService: UnauthorizedRequestService,
    private exceptionloggingService: DsLoggingService
  ) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const loginHint = localStorage.getItem(LocalStorageKey.LoginHint) || '';
    return next.handle(request)
      .pipe(tap(event => {
        if (event.type !== HttpEventType.Response) {
          return;
        }

        // Only calls to the API matter
        if (!request.url.startsWith(environment.azureApiManagementUrl)) {
          return;
        }

        if (event.status === 401 || event.status === 403) {
          // Handled by the catchError
          return;
        }

        this.unauthorizedRequestService.logSucceededRequest();
      }))
      .pipe(catchError((error: HttpErrorResponse) => {
        this.exceptionloggingService.logError(error);

        if (error.status === 401) {
          // in this case communityUuid needs to be removerd from the localstorage
          // to prevent the user from entering an infinite loop
          this.unauthorizedRequestService.logUnauthorizedRequest();
          return EMPTY;
        }

        if (error.status === 403) {
          // If there is no loginHint we need to login, else we get the region and app component wil handle the rest
          if (!loginHint || loginHint === '') {
            this.unauthorizedRequestService.logUnauthorizedRequest();
          }
        }

        // HttpClient fails to parse an empty 200 response
        // https://github.com/angular/angular/issues/18680
        if (error.status === 200) {
          const res = new HttpResponse({
            body: null,
            headers: error.headers,
            status: error.status,
            statusText: error.statusText,
            url: error.url
          });

          return of(res);
        }

        return throwError(() => error);
      }));
  }
}
