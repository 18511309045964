import { ApplicationRef, Injectable, OnDestroy } from '@angular/core';
import { SwUpdate, UnrecoverableStateEvent, VersionEvent, VersionInstallationFailedEvent } from '@angular/service-worker';

import { Subscription, interval } from 'rxjs';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServiceWorkerUpdaterService implements OnDestroy {
  private updateIntervalSubscription: Subscription;
  private updateVersionSubscription: Subscription;
  private updateUnrecoverableSubscription: Subscription;

  public constructor(
    public appRef: ApplicationRef,
    public updates: SwUpdate) { }

  public init(): void {
    this.checkForUpdate();
    this.versionUpdates();
    this.unrecoverable();
  }

  public ngOnDestroy(): void {
    this.updateIntervalSubscription.unsubscribe();
    this.updateVersionSubscription.unsubscribe();
    this.updateUnrecoverableSubscription.unsubscribe();
  }

  private checkForUpdate(): void {
    // polling for updates with `interval()`.
    const updateInterval = environment.name === ('prod' || 'uat')
      ? 30 * 60 * 1000
      : 10 * 60 * 1000;
    const updateIntervalSubscription = interval(updateInterval);

    this.updateIntervalSubscription = updateIntervalSubscription.subscribe(async () => {
      try {
        console.info('Checking for updates.');
        await this.updates.checkForUpdate().then(() => console.info('Update check done.'));
      } catch (err) {
        console.error('Failed to check for updates:', err);
      }
    });
  }

  private versionUpdates(): void {
    this.updateVersionSubscription = this.updates.versionUpdates.subscribe((event: VersionEvent) => {
      console.info('SERVICE_WORKER_VERSION_EVENT: ', event.type);
      switch (event.type) {
        case 'VERSION_READY':
          this.versionReady();
          break;
        case 'VERSION_INSTALLATION_FAILED':
          this.versionInstallationFailed(event);
      }
    });
  }

  private versionReady(): void {
    const updateTitle = 'Auto updating in a few seconds...';
    const updateBody = 'Newer version of the app is available.';

    console.info(updateTitle, updateBody);
    window.location.reload();
  }

  private versionInstallationFailed(event: VersionInstallationFailedEvent): void {
    const error = new Error('ServiceWorker installation failed with error: ' + event.error);
    console.error(error);
  }

  private unrecoverable(): void {
    this.updateUnrecoverableSubscription = this.updates.unrecoverable.subscribe((event: UnrecoverableStateEvent) => {
      const error = new Error('ServiceWorker failed with error: ' + event.reason);
      console.error(error);
    });
  }
}
